import classNames from 'classnames';
import React from 'react';

import { isPresent } from '../helpers';
import { GameTaskCompletable } from '../types';
import { TileImage } from './tile-image';

type TaskProps = {
  task: GameTaskCompletable;
  collect: () => void;
  tileClick: (item: GameTaskCompletable['items'][number]) => void;
};

export const Task: React.FC<TaskProps> = ({ task, collect, tileClick }) => {
  return (
    <div className="relative flex flex-row items-center justify-center px-1 bg-amber-100 border-2 rounded-lg mr-2 last:mr-0 min-w-[80px] w-max select-none">
      {task.items.filter(isPresent).map((t, i) => {
        return (
          <div
            onClick={() => tileClick(t)}
            className={classNames('relative', {
              "after:content-['✓'] after:block after:absolute after:bottom-0 after:right-0 after:rounded-full after:bg-green-500 after:text-[10px] after:w-4 after:h-4 after:text-center after:text-white after:font-bold":
                t.onBoard
            })}
            key={t.item + i}
          >
            <TileImage
              className="h-8 mr-1 last:mr-0"
              tile={t.item}
              level={t.level}
            />
          </div>
        );
      })}
      <div className="absolute top-0 right-0 -translate-y-full flex flex-row z-10 text-red-400">
        {task.reward.map((r) => (
          <span className="text-sm font-bold mr-0 last:mr-0" key={task.id}>
            ⭐ {r.value}
          </span>
        ))}
      </div>

      {task.canComplete && (
        <button
          className="absolute flex w-full h-full bg-green-400/40 items-end justify-center pb-[2px] outline-none"
          onClick={collect}
        >
          <span className="bg-green-600 text-white rounded-xl px-1 text-sm">
            Собрать
          </span>
        </button>
      )}
    </div>
  );
};
