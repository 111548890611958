import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import { TileBox, TileGenerator, TileModifier, TileName } from '../const';
import { useAppStore } from '../store';
import { Energy } from './energy';
import { TaskList } from './task-list';
import { TileImage } from './tile-image';

export const HeaderRow: React.FC<
  PropsWithChildren & Partial<{ className: string }>
> = ({ children, className }) => {
  return (
    <div
      className={classNames('flex flex-row gap-2 mb-2 last:mb-0', className)}
    >
      {children}
    </div>
  );
};

export type HeaderProps = {
  isGameScreen: boolean;
};

export const Header: React.FC<HeaderProps> = ({ isGameScreen }) => {
  const { points, addRandomTile } = useAppStore();

  const tiles = [
    TileBox.BOX,
    TileModifier.BATTERY,
    TileModifier.BELL,
    TileModifier.CAMERA,
    TileModifier.SCISSORS,
    TileGenerator.DONUT,
    TileGenerator.MACAROON
  ];

  const handleAddTile = (tile: TileName) => {
    addRandomTile(tile, 1, 1);
  };

  return (
    <header className="fixed top-0 left-1/2 flex flex-col w-[384px] scale-[var(--app-scale)] -translate-x-1/2 translate-y-[calc(14px*var(--app-scale))]  origin-top">
      <HeaderRow>
        <div className="block bg-white rounded-full px-2 h-max text-sm font-bold mt-[1px] mr-1">
          ⭐ {points}
        </div>
        <Energy />
      </HeaderRow>

      {isGameScreen ? (
        <HeaderRow className="w-full overflow-x-auto scrollbar-hide pt-4">
          {tiles.map((tile) => (
            <button
              key={tile}
              className="relative flex flex-row px-1 w-full max-w-[46px] min-w-[46px] bg-blue-100 items-center border-2 last:border-r rounded-lg mr-2 last:mr-0 "
              onClick={() => handleAddTile(tile)}
            >
              <TileImage
                className="h-8 min-w-max aspect-square"
                tile={tile}
                level={1}
              />
            </button>
          ))}

          <TaskList />
        </HeaderRow>
      ) : null}
    </header>
  );
};
