import {
  TileBox,
  TileGenerator,
  TileItem,
  TileModifier,
  TileName
} from '../const';
import {
  isTileBoxType,
  isTileGeneratorType,
  isTileItemType,
  isTileModifierType
} from '../helpers';
import { ItemsBox } from './box';
import { GameBox, GameBoxParams } from './box/box';
import {
  Donut,
  GameGenerator,
  GameGeneratorParams,
  Macaroon
} from './generator';
import {
  Cookie,
  Cupcake,
  GameItem,
  GameItemParams,
  PopTart,
  StarCookie
} from './item';
import {
  Battery,
  Bell,
  Camera,
  GameModifier,
  GameModifierParams,
  Scissors
} from './modifier';
import { GameElementParams } from './tile';

type ClassType<T> = new (...args: any[]) => T;

const gameItemMap: Record<TileItem, ClassType<GameItem>> = {
  [TileItem.COOKIE]: Cookie,
  [TileItem.STARCOOKIE]: StarCookie,
  [TileItem.POPTART]: PopTart,
  [TileItem.CUPCAKE]: Cupcake
};

const gameGeneratorMap: Record<TileGenerator, ClassType<GameGenerator>> = {
  [TileGenerator.MACAROON]: Macaroon,
  [TileGenerator.DONUT]: Donut
};

const gameModifierMap: Record<TileModifier, ClassType<GameModifier>> = {
  [TileModifier.SCISSORS]: Scissors,
  [TileModifier.CAMERA]: Camera,
  [TileModifier.BATTERY]: Battery,
  [TileModifier.BELL]: Bell
};

const gameBoxMap: Record<TileBox, ClassType<GameBox>> = {
  [TileBox.BOX]: ItemsBox
};

export class TileFactory {
  static getGameTile(type: TileName | 'RANDOM', params: GameElementParams) {
    if (type === 'RANDOM' || isTileItemType(type)) {
      return TileFactory.getGameItem(type, params);
    } else if (isTileGeneratorType(type)) {
      return TileFactory.getGameGenerator(type, params);
    } else if (isTileModifierType(type)) {
      return TileFactory.getGameModifier(type, params);
    } else if (isTileBoxType(type)) {
      return TileFactory.getGameBox(type, params);
    }
    throw new Error('Unknown tile type');
  }

  static getGameItem(type: TileItem | 'RANDOM', params: GameItemParams) {
    if (type === 'RANDOM') {
      const items = Object.values(gameItemMap);
      return new items[Phaser.Math.RND.between(0, items.length - 1)](params);
    }
    return new gameItemMap[type](params);
  }

  static getGameGenerator(type: TileGenerator, params: GameGeneratorParams) {
    return new gameGeneratorMap[type](params);
  }

  static getGameModifier(type: TileModifier, params: GameModifierParams) {
    return new gameModifierMap[type](params);
  }

  static getGameBox(type: TileBox, params: GameBoxParams) {
    return new gameBoxMap[type](params);
  }
}
