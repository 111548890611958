/**
 *
 * @param arr
 * @param predicate
 * @returns [x, y]
 */
export function findIndex2dArray<T>(
  arr: T[][],
  predicate: (elem: T) => boolean
): [number, number] | null {
  for (let i = 0; i < arr.length; i++) {
    for (let j = 0; j < arr[i].length; j++) {
      if (predicate(arr[i][j])) {
        return [j, i];
      }
    }
  }
  return null;
}
