export class Point {
  constructor(private _x: number, private _y: number) {}

  set x(xCord: number) {
    this.x = xCord;
  }

  get x(): number {
    return this._x;
  }

  set y(yCord: number) {
    this.y = yCord;
  }

  get y(): number {
    return this._y;
  }

  setValue(x: number): void;
  setValue(y: number): void;
  setValue(x: number, y: number): void;
  setValue(x?: number, y?: number): void {
    this._x = x || this._x || 0;
    this._y = y || this._y || 0;
  }

  isEqual(p: Point) {
    return this._x === p.x && this._y === p.y;
  }
}
