import { TileItem } from '../../const';
import { isTileGeneratorType } from '../../helpers';
import { BoardItem } from '../../types';
import { GameModifier } from './modifier';

export abstract class ModifierGameGenerator extends GameModifier {
  override canApply(tile: BoardItem<TileItem>): boolean {
    return isTileGeneratorType(tile.item);
  }

  protected calcModifiedElementsCount() {
    return 10 + (this.level - 1) * 4;
  }
}
