import { clamp } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { Constants, TileName } from '../const';
import { BoardPoint } from '../interfaces';
import { IImageConstructor } from '../interfaces/image.interface';

export enum TailTypeEnum {
  'GENERATOR' = 'GENERATOR',
  'ELEMENT' = 'ELEMENT',
  'MODIFIER' = 'MODIFIER',
  'BOX' = 'BOX'
}

export type TailType = `${TailTypeEnum}`;

export type GameElementParams = {
  scene: Phaser.Scene;
  point: BoardPoint;
  level?: number;
  id?: string;
};

const cornerSize = 10;
const borderColor = 0xfff333;
const borderAlpha = 0.8;
const borderOffset = cornerSize / 2;

export abstract class Tile extends Phaser.GameObjects.Image {
  protected readonly constants = Constants.getInstance();

  readonly id: string;
  readonly level: number = 1;
  boardPoint: BoardPoint = new BoardPoint(0, 0);
  readonly tileName: TileName;
  readonly tileType: TailType;

  readonly maxLevel: number;

  borderActive = false;
  private borderGroup!: Phaser.GameObjects.Group;

  get isMaxLevel(): boolean {
    return this.level === this.maxLevel;
  }

  constructor(
    params: IImageConstructor & { name: TileName; type: TailType } & Partial<{
        level: number;
        boardPoint: BoardPoint;
        id: string;
      }>
  ) {
    super(
      params.scene,
      params.x,
      params.y,
      `${params.texture}${params.level || 1}`,
      params.frame
    );
    this.id = params.id || uuidv4();

    this.tileName = params.name;
    this.tileType = params.type;

    this.maxLevel = this.constants.getMaxLevel(this.tileName);

    this.level = clamp(params.level ?? this.level, 1, this.maxLevel);

    this.boardPoint = params.boardPoint ?? this.boardPoint;

    this.setOrigin(0, 0);
    this.setInteractive({ useHandCursor: true });

    this.initBorder();
    this.scene.add.existing(this);
  }

  override destroy(fromScene?: boolean | undefined): void {
    this.borderGroup.destroy(true, true);
    super.destroy(fromScene);
  }

  private initBorder() {
    this.borderGroup = this.scene.add.group();
    const borderStyles = {
      topLeft: { x: -borderOffset, y: -borderOffset, size: cornerSize },
      topRight: {
        x: this.width - borderOffset,
        y: -borderOffset,
        size: cornerSize
      },
      bottomLeft: {
        x: -borderOffset,
        y: this.height - borderOffset,
        size: cornerSize
      },
      bottomRight: {
        x: this.width - borderOffset,
        y: this.height - borderOffset,
        size: cornerSize
      }
    };

    for (let corner in borderStyles) {
      const { x, y, size } = borderStyles[corner];
      const borderCorner = this.scene.add.rectangle(
        this.x + x,
        this.y + y,
        size,
        size,
        borderColor,
        borderAlpha
      );
      borderCorner.setOrigin(0, 0);
      this.borderGroup.add(borderCorner);
    }

    this.borderGroup.setVisible(false);
  }

  updatePosition(point: BoardPoint): void {
    this.boardPoint = point;

    const cords = this.boardPoint.getCords();

    this.x = cords.x;
    this.y = cords.y;
  }

  isEqual(tile: Tile): boolean {
    if (this.id === tile.id) {
      return true;
    }

    return (
      this.boardPoint.isEqual(tile.boardPoint) &&
      this.tileName === tile.tileName
    );
  }

  showBorder(): void {
    if (this.borderActive === false && this.scene) {
      this.borderGroup.setVisible(true);
      this.borderActive = true;
    }
  }

  hideBorder(): void {
    if (this.borderActive && this.scene) {
      this.borderGroup.setVisible(false);
      this.borderActive = false;
    }
  }
}
