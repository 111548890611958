import React from 'react';

import { useAppStore } from '../../store';
import { GameDialog, GameDialogTypeEnum } from '../../types/dialog';
import { ProgressMapDialog } from './progress-map';
import { RecipeDialog } from './recipe';
import { TileInfoDialog } from './tile-info';

export const DialogsWrapper: React.FC = () => {
  const { dialogs, closeDialog, removeTileById } = useAppStore();

  const dialogList = dialogs.map((d) => {
    switch (d.type) {
      case GameDialogTypeEnum.TILE_INFO:
        return (
          <TileInfoDialog
            key={d.id}
            tile={d.content}
            removeTail={() => handleRemoveTile(d)}
            close={() => handleCloseDialog(d.id)}
          />
        );
      case GameDialogTypeEnum.RECIPE:
        return (
          <RecipeDialog
            key={d.id}
            tile={d.content}
            close={() => handleCloseDialog(d.id)}
          />
        );
      case GameDialogTypeEnum.TILE_INFO:
        return (
          <TileInfoDialog
            key={d.id}
            tile={d.content}
            removeTail={() => handleRemoveTile(d)}
            close={() => handleCloseDialog(d.id)}
          />
        );
      case GameDialogTypeEnum.PROGRESS_MAP:
        return (
          <ProgressMapDialog
            key={d.id}
            close={() => handleCloseDialog(d.id)}
            {...d.content}
          />
        );
    }
    return null;
  });

  const handleRemoveTile = (p: GameDialog) => {
    if (p.type === GameDialogTypeEnum.TILE_INFO) {
      removeTileById(p.content.id);
    }
    handleCloseDialog(p.id);
  };

  const handleCloseDialog = (id: string) => {
    closeDialog(id);
  };

  return <>{dialogList}</>;
};
