import { StateCreator } from 'zustand';

import { Constants } from '../const';
import { AppStore } from './app-store';

const { energyIntervalTime } = Constants.getInstance();

export interface EnergySlice {
  energy: number;
  addEnergy: (by: number) => void;
  setEnergy: (energy: number) => void;
}

export const createEnergySlice: StateCreator<AppStore, [], [], EnergySlice> = (
  set,
  get
) => ({
  energy: 20,
  addEnergy(by: number) {
    const energy = Math.max(get().energy + by, 0);

    if (energy < 100) {
      queueMicrotask(() => {
        get().startEnergyTimer(energyIntervalTime);
      });
    }

    set((state) => ({ energy }));
  },
  setEnergy(energy: number) {
    set(() => ({ energy }));
  }
});
