import { StateCreator } from 'zustand';

import { AppStore } from './app-store';

export interface EnergyTimerSlice {
  ticks: number;
  energyTimer: NodeJS.Timeout | null;
  startEnergyTimer: (intervalTime: number) => void;
  stopEnergyTimer: () => void;
}

export const createEnergyTimerSlice: StateCreator<
  AppStore,
  [],
  [],
  EnergyTimerSlice
> = (set, get) => ({
  ticks: 0,
  energyTimer: null,
  startEnergyTimer: (intervalTime: number) => {
    if (get().energyTimer) {
      return;
    }

    const updateTicks = () => {
      const currentEnergy = get().energy;
      const currentTicks = get().ticks;
      if (currentEnergy < 100) {
        if (currentTicks > 0 && currentTicks % 60 === 0) {
          set({ ticks: currentTicks + 1, energy: currentEnergy + 1 });
        } else {
          set({ ticks: currentTicks + 1 });
        }
      } else {
        clearInterval(get().energyTimer!);
        set({ energyTimer: null, ticks: 0 });
      }
    };

    const energyTimer = setInterval(updateTicks, intervalTime);
    updateTicks();
    set({ energyTimer });
  },
  stopEnergyTimer: () => {
    clearInterval(get().energyTimer!);
    set({ energyTimer: null, ticks: 0 });
  }
});
