import { Constants } from './const';
import { BootScene } from './scenes/boot-scene';
import { GameScene } from './scenes/game-scene';

const constants = Constants.getInstance();

export const GameConfig: Phaser.Types.Core.GameConfig = {
  version: '1.0',

  scale: {
    autoCenter: Phaser.Scale.CENTER_HORIZONTALLY,
    mode: Phaser.Scale.FIT,
    width: constants.gameWidth,
    height: constants.gameHeight,
    parent: 'game-content'
  },

  type: Phaser.AUTO,
  scene: [BootScene, GameScene],
  backgroundColor: '#ffffff',
  render: { pixelArt: false, antialias: true }
};
