import React from 'react';
import { Link } from 'react-router-dom';

type FooterProps = { isGameScreen: boolean };

export const Footer: React.FC<FooterProps> = ({ isGameScreen }) => {
  return (
    <footer className="fixed bottom-0 left-1/2 flex flex-col w-[384px] h-[132px] scale-[var(--app-scale)] -translate-x-1/2 -translate-y-[calc(12px*var(--app-scale))] origin-bottom">
      <div className="relative flex flex-row items-end h-full">
        {isGameScreen ? (
          <Link
            to="lvl-progress"
            className="flex justify-center items-center border-2 border-blue-500 bg-white rounded-full text-2xl h-12 w-12 "
          >
            ⬅
          </Link>
        ) : (
          <Link
            to="/"
            className="absolute right-0 bottom-0 flex justify-center items-center border-2 border-green-700 bg-green-500 rounded-full text-3xl h-14 w-14"
          >
            🔄
          </Link>
        )}
      </div>
    </footer>
  );
};
