import { TileItem } from '../../const';
import { GameItem, GameItemParams } from './item';

export class StarCookie extends GameItem {
  constructor(params: GameItemParams) {
    super({
      ...params,
      texture: 'starcookie',
      name: TileItem.STARCOOKIE
    });
  }
}
