import { clamp, cloneDeep, omit } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { StateCreator } from 'zustand';

import { Constants, TileGenerator, TileItemArray, TileName } from '../const';
import {
  cellNumber,
  findIndex2dArray,
  isMissed,
  isPresent,
  isTileGeneratorType,
  randomInt,
  randomPosition2d,
} from '../helpers';
import { Point } from '../interfaces';
import {
  BoardItem,
  BoardItemModification,
  BoardItemModifications,
  BoardItemWithPoint,
  CreateBoardItem,
} from '../types/board-item';
import { GameDialogTypeEnum } from '../types/dialog';
import { AppStore } from './app-store';

const constants = Constants.getInstance();

export interface BoardSlice {
  board: (BoardItem | null)[][];
  addTile: (tile: CreateBoardItem) => void;
  addRandomTile: (
    type?: TileName | TileName[],
    minLevel?: number,
    maxLevel?: number
  ) => void;

  mergeTiles: (a: BoardItemWithPoint, b: BoardItemWithPoint) => void;
  swapTiles: (a: Point, b: Point) => void;
  updateTile: (
    toUpd: { id: string } & Partial<
      Pick<BoardItemWithPoint, 'level' | 'point'> & {
        modification: BoardItemModification | BoardItemModification[];
        isMergedItem: boolean;
        showInfo: boolean;
      }
    >
  ) => void;
  updateGeneratorTile: (
    toUpd: { id: string } & Partial<{
      energy: number;
      modification: BoardItemModification | BoardItemModification[];
      isMergedItem: boolean;
      showInfo: boolean;
    }>
  ) => void;
  removeTileById: (id: string) => void;
  removeTilesByTypeLevel: (tiles: Omit<BoardItem, 'id'>[]) => void;
}

export const createBoardSlice: StateCreator<AppStore, [], [], BoardSlice> = (
  set,
  get
) => ({
  board: generateEmptyBoard(),

  addTile({ point, ...tile }) {
    set((state) => {
      const board: (BoardItem | null)[][] = state.board.map((row) =>
        row.map((tile) => (tile ? omit(tile, 'isMergedItem') : null))
      );
      board[point.y][point.x] = { ...tile, id: uuidv4() };
      return { board };
    });
  },

  addRandomTile(type, minLevel = 1, maxLevel = 2) {
    const pos = randomPosition2d(
      get().board,
      constants.getDisabledCells(get().level)
    );

    if (isMissed(pos)) {
      return;
    }

    const min = clamp(minLevel, constants.minLevel, constants.maxLevel - 1);

    const tileType = Array.isArray(type)
      ? type[randomInt(0, type.length - 1)]
      : type
      ? type
      : TileItemArray[randomInt(0, TileItemArray.length - 1)];

    get().addTile({
      item: tileType,
      level: randomInt(
        min,
        clamp(maxLevel, min, constants.getMaxLevel(tileType))
      ),
      point: new Point(pos[0], pos[1])
    });
  },
  mergeTiles(a, b) {
    const disabledCells = constants.getDisabledCells(get().level);

    const t: BoardItem = {
      item: a.item,
      level: a.level + 1,
      id: uuidv4(),
      isMergedItem: true
    };

    if (isTileGeneratorType(a.item) && isTileGeneratorType(b.item)) {
      t.modifications = a.modifications || ({} as BoardItemModifications);

      Object.entries(b.modifications || {}).forEach(([key, value]) => {
        if (t.modifications) {
          t.modifications[key] = Math.max(
            (t.modifications[key] || 0) + value,
            0
          );
        } else {
          t.modifications = {
            [key]: value
          } as BoardItemModifications;
        }
      });
    }

    const aCellNum = cellNumber(a.point, constants.gridWidth);
    const bCellNum = cellNumber(b.point, constants.gridWidth);

    const canSwap = !(
      disabledCells.has(aCellNum) || disabledCells.has(bCellNum)
    );

    if (canSwap) {
      get().addDialog({ content: t, type: GameDialogTypeEnum.TILE_INFO });
    }

    set((state) => {
      const board: (BoardItem | null)[][] = state.board.map((row) =>
        row.map((tile) => (tile ? omit(tile, 'isMergedItem') : null))
      );

      if (canSwap) {
        board[a.point.y][a.point.x] = t;

        board[b.point.y][b.point.x] = null;
      }

      return { board };
    });
  },
  swapTiles(a, b) {
    const disabledCells = constants.getDisabledCells(get().level);

    const aCellNum = cellNumber(a, constants.gridWidth);
    const bCellNum = cellNumber(b, constants.gridWidth);

    const canSwap = !(
      disabledCells.has(aCellNum) || disabledCells.has(bCellNum)
    );

    set((state) => {
      const board: (BoardItem | null)[][] = state.board.map((row) =>
        row.map((tile) => (tile ? omit(tile, 'isMergedItem') : null))
      );

      if (canSwap) {
        const aTile = board[a.y][a.x];

        board[a.y][a.x] = board[b.y][b.x];
        board[b.y][b.x] = aTile;
      }

      return { board };
    });
  },
  updateTile({ id, level, point, modification, isMergedItem, showInfo }): void {
    set((state) => {
      const index = findIndex2dArray(state.board, (t) => t?.id === id);

      if (isMissed(index)) {
        return state;
      }

      const [x, y] = index;

      const board: (BoardItem | null)[][] = state.board.map((row) =>
        row.map((tile) => (tile ? omit(tile, 'isMergedItem') : null))
      );
      const foundedTile = board[y][x] as BoardItem;

      foundedTile.isMergedItem = isMergedItem;

      if (point) {
        board[y][x] = null;
        board[point.y][point.x] = foundedTile;
      }

      if (level) {
        foundedTile.level = clamp(level, 1, 4);
      }

      if (modification) {
        const applyModificationToElement = (m: BoardItemModification) => {
          if (foundedTile.modifications) {
            foundedTile.modifications[m.type] = Math.max(
              (foundedTile.modifications[m.type] || 0) + m.value,
              0
            );
          } else {
            foundedTile.modifications = {
              [m.type]: m.value
            } as BoardItemModifications;
          }
        };

        if (Array.isArray(modification)) {
          modification.forEach(applyModificationToElement);
        } else {
          applyModificationToElement(modification);
        }
      }

      if (showInfo) {
        state.addDialog({
          content: foundedTile,
          type: GameDialogTypeEnum.TILE_INFO
        });
      }

      return { board };
    });
  },

  updateGeneratorTile({ id, energy, modification, showInfo, isMergedItem }) {
    if (energy) {
      get().addEnergy(energy);
    }

    if (modification) {
      get().updateTile({ id, modification, showInfo, isMergedItem });
    }
  },
  removeTileById(id) {
    set((state) => {
      const sBoard = state.board;
      const board: (BoardItem | null)[][] = [];

      const height = sBoard.length;
      const width = sBoard[0].length;

      for (let y = 0; y < height; y++) {
        const row = new Array(width);
        for (let x = 0; x < width; x++) {
          const boardItem = sBoard[y][x];
          if (boardItem === null || boardItem.id === id) {
            row[x] = null;
          } else {
            row[x] = { ...boardItem };
          }
        }
        board[y] = row;
      }
      return { board };
    });
  },
  removeTilesByTypeLevel(tiles) {
    set((state) => {
      const sBoard = state.board;

      const tilesMap: Map<string, number> = new Map();

      for (const t of tiles) {
        const key = `${t.item}-${t.level}`;
        tilesMap.set(key, tilesMap.get(key) || 1);
      }
      const board: (BoardItem | null)[][] = [];

      const height = sBoard.length;
      const width = sBoard[0].length;

      for (let y = 0; y < height; y++) {
        const row = new Array(width);
        for (let x = 0; x < width; x++) {
          const boardItem = sBoard[y][x];
          if (boardItem === null) {
            row[x] = null;
            continue;
          }
          const k = `${boardItem.item}-${boardItem.level}`;

          const delTileCount = tilesMap.get(k);

          if (!delTileCount) {
            row[x] = cloneDeep(boardItem);
          } else {
            row[x] = null;
            tilesMap.set(k, delTileCount - 1);
          }
        }
        board[y] = row;
      }
      return { board };
    });
  }
});

function generateEmptyBoard(): BoardItem[][] {
  const board: BoardItem[][] = [...new Array(constants.gridHeight)].map(() =>
    new Array(constants.gridWidth).fill(null)
  );

  const pos = randomPosition2d(board, constants.getDisabledCells(1));

  if (isPresent(pos)) {
    const [x, y] = pos;

    board[y][x] = {
      id: uuidv4(),
      item: TileGenerator.MACAROON,
      level: 1
    };
  }

  return board;
}
