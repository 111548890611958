import { TileGenerator } from '../../const';
import { GameGenerator, GameGeneratorParams } from './generator';

export class Macaroon extends GameGenerator {
  constructor(params: GameGeneratorParams) {
    super({
      ...params,
      texture: 'macaroon',
      name: TileGenerator.MACAROON
    });
  }
}
