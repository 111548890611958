import { StateCreator } from 'zustand';

import { AppStore } from './app-store';

export interface UserSlice {
  level: number;
  points: number;
  addPoints: (points: number) => void;
  setLevel: (level: number) => void;
  setNextLevel: () => void;
}

export const createUserSlice: StateCreator<AppStore, [], [], UserSlice> = (
  set
  // get
) => ({
  level: 1,
  points: 10,
  addPoints(p) {
    set(({ points }) => ({ points: Math.max(points - p, 0) }));
  },
  setLevel(l) {
    set(() => ({ level: Math.max(l, 1) }));
  },
  setNextLevel() {
    set(({ level }) => ({ level: level + 1 }));
  }
});
