import { TileModifier } from '../../const';
import { isTileItemType } from '../../helpers';
import { appStore, selectCountEmptyCells } from '../../store';
import { BoardItem } from '../../types';
import { GameModifierParams } from './modifier';
import { ModifierGameItem } from './modifier-item';

export class Scissors extends ModifierGameItem {
  constructor(params: GameModifierParams) {
    super({
      ...params,
      texture: 'scissors',
      name: TileModifier.SCISSORS
    });
  }

  override canApply(tile: BoardItem): boolean {
    const state = appStore.getState();

    const disabledCells = this.constants.getDisabledCells(state.level);

    return (
      isTileItemType(tile.item) &&
      tile.level > 1 &&
      tile.level <= this.level + 1 &&
      selectCountEmptyCells(appStore.getState(), disabledCells) > 0
    );
  }

  override modify(tile: BoardItem): void {
    if (!this.canApply(tile)) {
      return;
    }

    const { addRandomTile, updateTile, removeTileById } = appStore.getState();

    tile.level -= 1;

    removeTileById(this.id);

    addRandomTile(tile.item, tile.level, tile.level);
    addRandomTile(tile.item, tile.level, tile.level);
    updateTile({ ...tile, showInfo: true, isMergedItem: true });
  }
}
