import { TileItem } from '../../const';
import { isTileItemType } from '../../helpers';
import { BoardItem } from '../../types';
import { GameModifier } from './modifier';

export abstract class ModifierGameItem extends GameModifier {
  override canApply(tile: BoardItem<TileItem>): boolean {
    if (isTileItemType(tile.item)) {
      return tile.level <= this.level;
    }
    return false;
  }
}
