import React from 'react';

import { beautifyTicks } from '../helpers/beautify-ticks';
import { useAppStore } from '../store';
import { ChartBar } from './chart-bar';

export const Energy = () => {
  const { energy, setEnergy, ticks } = useAppStore();

  const timer = beautifyTicks(ticks);

  const addEnergyHandler = () => {
    if (energy < 100) {
      setEnergy(100);
    }
  };

  return (
    <div className="h-full flex flex-row gap-1 flex-1 items-center pb-[10px]">
      <div className="relative flex-1">
        <div className="relative flex-1 border-2 border-black rounded-2xl overflow-hidden">
          <div className="absolute top-1/2 -left-[2px] -translate-y-1/2 z-10 text-2xl leading-none">
            ⚡
          </div>
          <ChartBar value={energy} />
        </div>
        {ticks > 0 && (
          <div className="absolute top-full left-1/2  -translate-x-1/2  px-2 bg-black text-white font-bold text-[10px] rounded-t-none rounded-lg ">
            <span>{timer}</span>
          </div>
        )}
      </div>

      <button
        className="flex items-center justify-center bg-green-400 text-white rounded-full w-4 h-4 pb-[2px] "
        onClick={addEnergyHandler}
      >
        +
      </button>
    </div>
  );
};
