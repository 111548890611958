import { StateCreator } from 'zustand';

import { Constants } from '../const';
import { generateUniqueTasks } from '../helpers';
import { BoardItem, GameTask, TaskRewardEnum } from '../types';
import { AppStore } from './app-store';

const constants = Constants.getInstance();

export interface TaskSlice {
  taskList: GameTask[];
  completedTasks: number;
  addTask: () => void;
  completeTask: (id: GameTask) => void;
}

export const createTaskSlice: StateCreator<AppStore, [], [], TaskSlice> = (
  set,
  get
) => ({
  taskList: generateUniqueTasks(5, undefined, { maxLevel: 1 }),
  completedTasks: 0,
  addTask() {},
  completeTask(task) {
    const {
      addEnergy,
      removeTilesByTypeLevel,
      setLevel,
      completedTasks,
      level
    } = get();
    if (task) {
      addEnergy(task.items.length * 2);
      removeTilesByTypeLevel(task.items as unknown as BoardItem[]);
    }

    const possibleLevel = constants.getCompletedTasksToLevel(completedTasks);

    if (possibleLevel !== level) {
      setLevel(possibleLevel);
    }

    set((state) => {
      const tasks = state.taskList.filter((t) => t.id !== task.id);

      const points =
        task.reward.find((r) => r.type === TaskRewardEnum.POINTS)?.value ?? 0;

      const minLevel = Math.max(state.level - 1, 1);

      tasks.push(
        generateUniqueTasks(1, state.taskList, {
          minLevel,
          maxLevel: state.level + 2
        })[0]
      );

      return {
        taskList: tasks,
        completedTasks: state.completedTasks + 1,
        points: state.points + points
      };
    });
  }
});
