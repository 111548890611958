import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import { Portal } from '../portal';
import { Backdrop } from './backdrop';

export const Dialog: React.FC<
  PropsWithChildren & Partial<{ className: string; showBackdrop: boolean }>
> = ({ children, className, showBackdrop }) => {
  return (
    <Portal>
      {showBackdrop && <Backdrop />}
      <div
        className={classNames(
          'fixed flex flex-col w-[384px] left-1/2 -translate-x-1/2 scale-[var(--app-scale)] z-10',
          className
        )}
      >
        {children}
      </div>
    </Portal>
  );
};
