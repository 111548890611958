import { TileItem } from '../../const';
import { GameItem, GameItemParams } from './item';

export class PopTart extends GameItem {
  constructor(params: GameItemParams) {
    super({
      ...params,
      texture: 'poptart',
      name: TileItem.POPTART
    });
  }
}
