import { memoize } from 'lodash';

import { GameTask, GameTaskCompletable } from '../types';
import { AppStore } from './app-store';
import { selectFlattenBoardItems } from './board-selectors';

export const selectTasksWithStatus = memoize(
  (state: AppStore): GameTaskCompletable[] => {
    const boardItems = selectFlattenBoardItems(state);
    const { taskList } = state;

    return taskList
      .map<GameTaskCompletable>((t) => {
        const items = t.items.map((tItem) => ({
          ...tItem,
          onBoard: boardItems.some(
            (bItem) => bItem.item === tItem?.item && bItem.level === tItem.level
          )
        })) as GameTask['items'];

        return {
          id: t.id,
          reward: t.reward,
          items,
          canComplete: items.every((item) => !!item?.onBoard)
        };
      })
      .sort((a, b) =>
        a.canComplete === b.canComplete ? 0 : a.canComplete ? -1 : 1
      );
  }
);
