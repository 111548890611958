import { v4 as uuidv4 } from 'uuid';
import { StateCreator } from 'zustand';

import { GameDialog, GameDialogTypeEnum } from '../types/dialog';

export interface DialogSlice {
  dialogs: GameDialog[];
  addDialog: (payload: Omit<GameDialog, 'id'>) => void;
  closeDialog: (id: string) => void;
  closeDialogInfo: () => void;
}

export const createDialogSlice: StateCreator<DialogSlice> = (set, get) => ({
  dialogs: [],
  addDialog(payload) {
    if (
      payload.type === GameDialogTypeEnum.TILE_INFO &&
      get().dialogs.some(
        (d) => d.content?.['id'] === (payload.content as any).id
      )
    ) {
      return;
    }

    const dialog = { ...payload, id: uuidv4() } as GameDialog;

    set((state) => {
      const dialogs =
        payload.type === GameDialogTypeEnum.TILE_INFO
          ? state.dialogs.filter((d) => d.type !== GameDialogTypeEnum.TILE_INFO)
          : state.dialogs;

      return {
        dialogs: [...dialogs, dialog]
      };
    });
  },
  closeDialog(id) {
    set((state) => ({
      dialogs: state.dialogs.filter((d) => d.id !== id)
    }));
  },
  closeDialogInfo() {
    set((state) => ({
      dialogs: state.dialogs.filter(
        (d) => d.type !== GameDialogTypeEnum.TILE_INFO
      )
    }));
  }
});
