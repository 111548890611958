import { Constants } from '../const';
import { Point } from './point';

export class BoardPoint extends Point {
  private readonly constants = Constants.getInstance();

  override isEqual(p: Point) {
    if (!(p instanceof BoardPoint)) {
      return false;
    }
    return this.x === p.x && this.y === p.y;
  }

  getCords(): { x: number; y: number } {
    return {
      x: this.x * this.constants.tileWidth,
      y: this.y * this.constants.tileHeight + this.constants.headerBottomOffset
    };
  }

  static fromCords(x: number, y: number): BoardPoint {
    const { tileHeight, tileWidth, headerBottomOffset } =
      Constants.getInstance();

    return new BoardPoint(
      Math.floor(Math.max(x, 0) / tileWidth),
      Math.max(Math.floor(Math.max(y - headerBottomOffset, 0) / tileHeight))
    );
  }
}
