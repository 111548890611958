import { TileBox } from '../../const';
import { GameBox, GameBoxParams } from './box';

export class ItemsBox extends GameBox {
  constructor(params: GameBoxParams) {
    super({
      ...params,
      texture: 'box',
      name: TileBox.BOX
    });
  }
}
