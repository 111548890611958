import { BoardItem } from './board-item';

export enum GameDialogTypeEnum {
  'TILE_INFO' = 'TILE_INFO',
  'RECIPE' = 'RECIPE',
  'PROGRESS_MAP' = 'PROGRESS_MAP',
  'MAIN' = 'MAIN'
}
export type GameDialogType = `${GameDialogTypeEnum}`;

export type GameDialog =
  | {
      id: string;
    } & (
      | {
          content: BoardItem;
          type: GameDialogTypeEnum.TILE_INFO;
        }
      | {
          content: BoardItem;
          type: GameDialogTypeEnum.RECIPE;
        }
      | {
          content: { level: number };
          type: GameDialogTypeEnum.PROGRESS_MAP;
        }
      | {
          content?: Record<string, unknown>;
          type: GameDialogTypeEnum.MAIN;
        }
    );

export type ClosableDialog = {
  close?: () => void;
};
