import { TileItem, TileModifier } from '../../const';
import { appStore } from '../../store';
import { BoardItem } from '../../types';
import { GameModifierParams } from './modifier';
import { ModifierGameGenerator } from './modifier-generator';

export class Bell extends ModifierGameGenerator {
  constructor(params: GameModifierParams) {
    super({
      ...params,
      texture: 'bell',
      name: TileModifier.BELL
    });
  }

  override modify(tile: BoardItem<TileItem>) {
    if (!this.canApply(tile)) {
      return;
    }

    const { updateGeneratorTile, removeTileById } = appStore.getState();

    removeTileById(this.id);

    updateGeneratorTile({
      id: tile.id,
      modification: {
        type: TileModifier.BELL,
        value: this.calcModifiedElementsCount()
      },
      isMergedItem: true,
      showInfo: true
    });
  }
}
