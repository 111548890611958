export function pointInBox(
  box: { width: number; height: number; x: number; y: number },
  point: { x: number; y: number }
): boolean {
  return (
    box.x < point.x &&
    box.x + box.width > point.x &&
    box.y < point.y &&
    box.y + box.height > point.y
  );
}
