import classNames from 'classnames';
import React from 'react';

import { TileName } from '../const';

type TileImageProps = {
  tile: TileName;
  level: string | number;
  className?: string;
};

export const TileImage: React.FC<TileImageProps> = ({
  tile,
  level,
  className
}) => {
  return (
    <img
      draggable={false}
      className={classNames('inline-block', className)}
      src={`/assets/images/${tile.toLocaleLowerCase()}${level}.png`}
    />
  );
};
