import React, { useEffect } from 'react';

export const Backdrop: React.FC = () => {
  return (
    <div
      id="backdrop"
      className="fixed top-0 left-0 w-screen h-screen bg-black/40 z-0 fade-in-animation pointer-events-auto"
    ></div>
  );
};
