import { memoize } from 'lodash';

const TEXTURES = [
  'cookie',
  'macaroon',
  'poptart',
  'starcookie',
  'cupcake',
  'donut',
  'scissors',
  'camera',
  'battery',
  'bell',
  'box'
  // 'cookie',
  // 'croissant',
  // 'cupcake',
  // 'donut',
  // 'eclair',
  // 'macaroon',
  // 'pie'
] as const;

export type Texture = (typeof TEXTURES)[number];

export enum TileItem {
  'COOKIE' = 'COOKIE',
  'POPTART' = 'POPTART',
  'STARCOOKIE' = 'STARCOOKIE',
  'CUPCAKE' = 'CUPCAKE'
}

export const TileItemArray = Object.values(TileItem);

export type TileItemType = `${TileItem}`;

export enum TileGenerator {
  'MACAROON' = 'MACAROON',
  'DONUT' = 'DONUT'
}

export const TileGeneratorArray = Object.values(TileGenerator);

export type TileGeneratorType = `${TileGenerator}`;

export enum TileModifier {
  'SCISSORS' = 'SCISSORS',
  'CAMERA' = 'CAMERA',
  'BATTERY' = 'BATTERY',
  'BELL' = 'BELL'
}

export const TileModifierArray = Object.values(TileModifier);

export type TileModifierType = `${TileModifier}`;

export enum TileBox {
  'BOX' = 'BOX'
}

export const TileBoxArray = Object.values(TileBox);

export type TileBoxType = `${TileBox}`;

export const TileArray = [
  ...TileItemArray,
  ...TileGeneratorArray,
  ...TileModifierArray,
  ...TileBoxArray
];

export type TileName =
  | TileGeneratorType
  | TileItemType
  | TileModifierType
  | TileBoxType;

export class Constants {
  public readonly score: number = 0;
  public readonly highscore: number = 0;
  public readonly gridWidth: number = 6;
  public readonly gridHeight: number = 9;
  public readonly tileWidth: number = 64;
  public readonly tileHeight: number = 72;
  public readonly headerHeight: number = 100;
  public readonly headerMarginBottom: number = 10;
  public readonly bottomHeight: number = 132;
  public readonly textures = TEXTURES;

  public readonly energyIntervalTime = 1e3;

  public readonly maxLevel: number = 4;
  public readonly minLevel: number = 1;

  private readonly maxLevelMap: Record<TileName, number> = {
    COOKIE: 4,
    POPTART: 4,
    STARCOOKIE: 4,
    CUPCAKE: 5,

    MACAROON: 4,
    DONUT: 4,

    SCISSORS: 4,
    CAMERA: 4,
    BATTERY: 4,
    BELL: 4,

    BOX: 4
  };

  private readonly modifierIcon: Record<string, string> = {
    [TileModifier.BATTERY]: '🔋',
    [TileModifier.BELL]: '🔔'
  };

  private readonly disabledCellsByLevel: (Set<number> | undefined)[] = [
    undefined,
    new Set([0, 1, 6, 7]), //2
    new Set([2, 3, 8, 9]), //3
    new Set([4, 5, 10, 11]), //4
    new Set([42, 43, 44, 45, 48, 49, 50, 51]), //5
    new Set([46, 47, 52, 53]) //6
  ];

  private readonly disabledGroupColors: Record<number, string> = {
    2: '#6549da',
    3: '#a86cad',
    4: '#3c3b6e',
    5: '#b22234',
    6: '#4a9ee7'
  };

  private readonly producedItemsByGeneratorMap: Record<
    TileGeneratorType,
    TileItem[]
  > = {
    MACAROON: [TileItem.STARCOOKIE, TileItem.POPTART],
    DONUT: [TileItem.COOKIE, TileItem.CUPCAKE]
  };

  private static instance: Constants | null = null;

  static getInstance() {
    if (this.instance === null) {
      this.instance = new Constants();
    }
    return this.instance;
  }

  get headerBottomOffset(): number {
    return this.headerHeight + this.headerMarginBottom;
  }

  get gameHeight(): number {
    return (
      this.gridHeight * this.tileHeight +
      this.headerBottomOffset +
      this.bottomHeight
    );
  }

  get gameWidth(): number {
    return this.gridWidth * this.tileWidth;
  }

  get boardCapacity(): number {
    return this.gridHeight * this.gridWidth;
  }

  getMaxLevel(type: TileName): number {
    return this.maxLevelMap[type];
  }

  getProducedItems = (type: TileGeneratorType): TileItem[] => {
    return this.producedItemsByGeneratorMap[type];
  };

  getGeneratorsByTileType = memoize((type: TileItem): TileGeneratorType[] => {
    return Object.entries(this.producedItemsByGeneratorMap)
      .filter(([_, tileItems]) => tileItems.includes(type))
      .map(([key]) => key as TileGeneratorType);
  });

  getDisabledCells = memoize((level: number): Set<number> => {
    if (level > this.disabledCellsByLevel.length) {
      return new Set<number>();
    }

    return new Set(
      this.disabledCellsByLevel.reduce((arr, s, i) => {
        if (s && i + 1 > level) {
          return [...arr, ...s];
        }
        return arr;
      }, [] as number[])
    );
  });

  getDisabledCellsMap = memoize(
    (level: number): Record<number, Set<number>> | null => {
      if (level > this.disabledCellsByLevel.length) {
        return null;
      }
      const disabled: Record<number, Set<number>> = {};

      for (let i = level; i < this.disabledCellsByLevel.length; i++) {
        const cells = this.disabledCellsByLevel[i];
        if (cells) {
          disabled[i + 1] = cells;
        }
      }

      return disabled;
    }
  );

  getColorGroup = (level: number): string => {
    return this.disabledGroupColors[level] || '#ffffff';
  };

  getModifierIcon = (modifier: string): string =>
    this.modifierIcon[modifier] || '';

  getCompletedTasksToLevel = (completedTasks: number) => {
    return Math.floor(completedTasks / 3) + 1;
  };
}
