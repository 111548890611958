import { BoardPoint, Point } from '../interfaces';

export function cellNumber(p: Point, boardWidth): number;
export function cellNumber(x: number, y: number, boardWidth: number): number;
export function cellNumber(
  pointOrX: number | Point,
  boardWidthOrY: number,
  boardWidth?: number
): number {
  if (pointOrX instanceof Point) {
    return boardWidthOrY * pointOrX.y + pointOrX.x;
  }

  return (boardWidth || 0) * boardWidthOrY + pointOrX;
}

export const cellNumberToPoint = (
  cellNum: number,
  boardWidth: number
): BoardPoint => {
  const y = Math.floor(cellNum / boardWidth);
  const x = cellNum % boardWidth;

  return new BoardPoint(x, y);
};
