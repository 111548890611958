import {
  TileBox,
  TileBoxArray,
  TileGenerator,
  TileGeneratorArray,
  TileItem,
  TileItemArray,
  TileModifier,
  TileModifierArray,
  TileName
} from '../const';
import { GameModifier, Tile } from '../objects';
import { GameBox } from '../objects/box/box';
import { GameGenerator } from '../objects/generator/generator';
import { GameItem } from '../objects/item/item';

export function isTileItemType(tile: TileName): tile is TileItem {
  return (TileItemArray as string[]).includes(tile);
}

export function isTileGeneratorType(tile: TileName): tile is TileGenerator {
  return (TileGeneratorArray as string[]).includes(tile);
}

export function isTileModifierType(tile: TileName): tile is TileModifier {
  return (TileModifierArray as string[]).includes(tile);
}

export function isTileBoxType(tile: TileName): tile is TileBox {
  return (TileBoxArray as string[]).includes(tile);
}

export function isTile(tile: unknown): tile is Tile {
  return tile instanceof Tile;
}

export function isTileGenerator(tile: Tile): tile is GameGenerator {
  return tile instanceof GameGenerator;
}

export function isTileItem(tile: Tile): tile is GameItem {
  return tile instanceof GameItem;
}

export function isTileModifier(tile: Tile): tile is GameModifier {
  return tile instanceof GameModifier;
}

export function isTileBox(tile: Tile): tile is GameBox {
  return tile instanceof GameBox;
}
