import classNames from 'classnames';
import React from 'react';

import { ChartBar } from './chart-bar';

const LevelProgressButton: React.FC<
  { icon: 'HAMMER' | 'BRUSH'; value: number } & Partial<{ className: string }>
> = ({ icon, className, value }) => {
  return (
    <div className={classNames('flex flex-row relative pr-24', className)}>
      <span className="absolute top-1/2 -translate-y-1/2 left-12  font-bold rounded-r-full  text-white pl-3 pr-2 py-1 bg-gradient-to-r from-transparent to-blue-900">
        ⭐ {value}
      </span>
      <button className="flex items-center justify-center aspect-square h-14 w-14 rounded-full border-4 border-white text-4xl z-10 ">
        {icon === 'BRUSH' ? '🖌️' : '🔨'}
      </button>
    </div>
  );
};

export const LevelProgress: React.FC = () => {
  return (
    <div className="fixed top-[calc(80px*var(--app-scale))] left-1/2 flex flex-col w-[384px] h-[calc(var(--app-height)-182px)] scale-[var(--app-scale)] -translate-x-1/2 origin-top bg-white/30 rounded-xl p-2">
      <div className="h-6 rounded-xl overflow-hidden">
        <ChartBar value={40} />
      </div>

      <div className="absolute top-11 left-5 w-max">
        <LevelProgressButton icon="HAMMER" value={150} />
      </div>
      <div className="absolute top-36 left-32 w-max">
        <LevelProgressButton icon="BRUSH" value={150} />
      </div>
    </div>
  );
};
