import { TileItem } from '../../const';
import { GameItem, GameItemParams } from './item';

export class Cookie extends GameItem {
  constructor(params: GameItemParams) {
    super({
      ...params,
      texture: 'cookie',
      name: TileItem.COOKIE
    });
  }
}
