import { v4 as uuidv4 } from 'uuid';

import { Constants, TileItem, TileItemArray } from '../const';
import { GameTask, TaskRewardEnum } from '../types/task';
import { randomInt } from './random';

const MAX_ITERATIONS = 1000;

export function generateTask(
  minLevel: number = 1,
  maxLevel: number = 4,
  itemsArray: TileItem[] = TileItemArray
): GameTask {
  const countItems = randomInt(1, 3);

  const items = [] as unknown as GameTask['items'];

  let i = 0;

  while (items.length < countItems) {
    const item: TileItem = itemsArray[randomInt(0, TileItemArray.length - 1)];

    const tileMaxLevel = Constants.getInstance().getMaxLevel(item);

    if (items.every((e) => e?.item !== item)) {
      items.push({
        item,
        level: randomInt(minLevel, Math.min(tileMaxLevel, maxLevel))
      });
    }
    if (i > MAX_ITERATIONS) {
      break;
    }
    i++;
  }

  return {
    id: '',
    items,
    reward: []
  };
}

export function generateUniqueTasks(
  count: number,
  existsGameTasks: GameTask[] = [],
  {
    minLevel,
    maxLevel,
    itemsArray
  }: Partial<{
    minLevel: number;
    maxLevel: number;
    itemsArray: TileItem[];
  }> = {}
): GameTask[] {
  const tasks: GameTask[] = [];

  let i = 0;
  while (tasks.length < count) {
    const task = generateTask(minLevel, maxLevel, itemsArray);

    if (
      task.items.length > 0 &&
      isUniqueTask(existsGameTasks, task) &&
      isUniqueTask(tasks, task)
    ) {
      tasks.push({
        id: uuidv4(),
        items: task.items,
        reward: [{ type: TaskRewardEnum.POINTS, value: task.items.length * 10 }]
      });
    }

    if (i > MAX_ITERATIONS) {
      break;
    }
    i++;
  }

  return tasks;
}

function isUniqueTask(arr: GameTask[], target: GameTask): boolean {
  for (const t of arr) {
    if (t.id === target.id) {
      return false;
    }

    if (
      t.items.length === target.items.length &&
      t.items.every((tItem) =>
        target.items.some(
          (targetItems) =>
            targetItems?.item === tItem?.item &&
            targetItems?.level === tItem?.level
        )
      )
    ) {
      return false;
    }
  }

  return true;
}
