import './index.css';
import 'phaser';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

import { Game, LevelProgress } from './components';
import { Constants } from './const';
import { throttle } from './helpers';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Game />,
    children: [
      {
        path: 'lvl-progress',
        element: <LevelProgress />
      }
    ]
  },
  {
    path: '*',
    element: <Navigate to="/" replace />
  }
]);

const container = document.getElementById('game');

const reactRoot = createRoot(container);

reactRoot.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

const root = document.documentElement;

const { gameWidth } = Constants.getInstance();

const [resizeHandler] = throttle(() => {
  const gameBoard = document.querySelector('canvas');
  if (gameBoard) {
    setTimeout(() => {
      const { width } = gameBoard.getBoundingClientRect();
      const height = `${window.screen.height}px`;

      root.style.setProperty('--game-width', width + 'px');
      root.style.setProperty('--app-scale', (width / gameWidth).toString());
      root.style.setProperty('--app-height', height);
    });
  }
}, 50);

window.addEventListener('resize', resizeHandler);
resizeHandler();
