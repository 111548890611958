import { TileItem, TileModifier } from '../../const';
import { appStore } from '../../store';
import { BoardItem } from '../../types';
import { GameModifierParams } from './modifier';
import { ModifierGameItem } from './modifier-item';

export class Camera extends ModifierGameItem {
  constructor(params: GameModifierParams) {
    super({
      ...params,
      texture: 'camera',
      name: TileModifier.CAMERA
    });
  }

  override modify(tile: BoardItem<TileItem>) {
    if (!this.canApply(tile)) {
      return;
    }

    const { addRandomTile, removeTileById, updateTile } = appStore.getState();

    removeTileById(this.id);
    addRandomTile(tile.item, tile.level, tile.level);
    updateTile({ id: tile.id, isMergedItem: true, showInfo: true });
  }
}
