import React, { useRef } from 'react';

import useOutside from '../../hooks/useOutside';
import { ClosableDialog } from '../../types';
import { Dialog } from './dialog';

type ProgressMapProps = ClosableDialog & {
  level: number;
};

export const ProgressMapDialog: React.FC<ProgressMapProps> = ({
  level,
  close
}) => {
  // const constants = Constants.getInstance();

  const ref = useRef<HTMLDivElement>(null);

  useOutside(ref, () => close?.());

  return (
    <Dialog
      className="top-1/2 -translate-y-1/2 origin-center p-2 z-50"
      showBackdrop={true}
    >
      <div
        className="relative -my-2 bg-[] text-black rounded-3xl border-4 border-rose-300 recipe-dialog-animation overflow-hidden"
        ref={ref}
      >
        <img
          src="/assets/images/progress-map.jpg"
          alt="progress map"
          draggable={false}
          className="object-cover object-center"
        />
      </div>
    </Dialog>
  );
};
