import { Texture, TileName } from '../../const';
import { BoardPoint } from '../../interfaces';
import { GameElementParams, Tile } from '../tile';

export type GameItemParams = GameElementParams;

export abstract class GameItem extends Tile {
  constructor({
    scene,
    texture,
    name,
    level,
    point,
    id
  }: {
    scene: Phaser.Scene;
    point: BoardPoint;
    name: TileName;
    texture: Texture;
    level?: number;
    id?: string;
  }) {
    super({
      scene,
      ...point.getCords(),
      texture,
      name,
      type: 'ELEMENT',
      boardPoint: point,
      id,
      level
    });
  }
}
