import { TileItemType } from '../const';

type GameItem = {
  item: TileItemType;
  level: number;
  onBoard?: boolean;
};

export enum TaskRewardEnum {
  'POINTS' = 'POINTS',
  'BOX' = 'BOX'
}

export type GameTaskReward = { type: TaskRewardEnum; value: number };

export type GameTask = {
  id: string;
  items: [GameItem, GameItem?, GameItem?];
  reward: GameTaskReward[];
};

export type GameTaskCompletable = GameTask & { canComplete: boolean };
