import { isPresent } from './is-present.helper';

export function randomPosition2d(
  arr: unknown[][],
  disabledCells?: Set<number>
): [x: number, y: number] | null {
  const emptyCells: [x: number, y: number][] = [];
  const width = arr[0].length;
  for (let row = 0; row < arr.length; row++) {
    for (let col = 0; col < width; col++) {
      const cellNum = width * row + col;
      if (!isPresent(arr[row][col]) && !disabledCells?.has(cellNum)) {
        emptyCells.push([col, row]);
      }
    }
  }

  if (emptyCells.length === 0) {
    return null;
  }

  const randomIndex = Math.floor(Math.random() * emptyCells.length);
  return emptyCells[randomIndex];
}
