import { Texture, TileName } from '../../const';
import { BoardPoint } from '../../interfaces';
import { BoardItem } from '../../types';
import { GameElementParams, Tile } from '../tile';

export type GameModifierParams = GameElementParams;

export abstract class GameModifier extends Tile {
  constructor({
    scene,
    texture,
    name,
    level,
    point,
    id
  }: {
    scene: Phaser.Scene;
    point: BoardPoint;
    name: TileName;
    texture: Texture;
    level?: number;
    id?: string;
  }) {
    super({
      scene,
      ...point.getCords(),
      texture,
      name,
      type: 'MODIFIER',
      boardPoint: point,
      id,
      level
    });
  }

  abstract canApply(tile: BoardItem): boolean;

  abstract modify(tile: BoardItem): void;
}
