import React, { useEffect, useRef } from 'react';

import { Constants } from '../../const';
import { isTileItemType } from '../../helpers';
import useOutside from '../../hooks/useOutside';
import { BoardItem, ClosableDialog } from '../../types';
import { TileImage } from '../tile-image';
import { Dialog } from './dialog';

const constants = Constants.getInstance();

type TileInfoDialogProps = ClosableDialog & {
  tile: BoardItem;
  removeTail?: () => void;
};

export const TileInfoDialog: React.FC<TileInfoDialogProps> = ({
  tile,
  removeTail,
  close
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const isMaxLevel = constants.getMaxLevel(tile.item) === tile.level;

  const canDelete = isTileItemType(tile.item);

  const modifications = (
    <>
      {Object.entries(tile.modifications || {})
        .filter(([, value]) => value)
        .map(([type, value]) => (
          <span key={type} className="text-sm font-bold mr-2 last:mr-0">
            {constants.getModifierIcon(type)} - {value}
          </span>
        ))}
    </>
  );

  useOutside(ref, () => close?.());

  return (
    <Dialog className="bottom-[calc(70px*var(--app-scale))] origin-bottom">
      <div
        className="flex flex-row h-14 py-1 px-2 bg-white text-black rounded-lg info-dialog-animation"
        ref={ref}
      >
        <TileImage className="h-12" tile={tile.item} level={tile.level} />

        <div className="flex flex-col flex-1 ml-2">
          <span className="block text-sm leading-none text-gray-600">
            Уровень {tile.level}
          </span>
          <span className="flex flex-1 items-center text-xl font-bold leading-none">
            {tile.item}
          </span>
          <span className="block text-sm leading-none text-gray-600">
            {isMaxLevel ? 'Максимальный уровень' : 'Можно объединить'}
          </span>
        </div>

        <div className="flex items-center justify-center">
          {canDelete && (
            <button
              className="pt-[2px] pb-1 px-2 bg-red-500 text-white rounded-3xl"
              onClick={removeTail}
            >
              Удалить
            </button>
          )}
          {modifications}
        </div>
      </div>
    </Dialog>
  );
};
