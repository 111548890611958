import { useStore } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { createStore } from 'zustand/vanilla';

import { BoardSlice, createBoardSlice } from './board-slice';
import { createDialogSlice, DialogSlice } from './dialog-slice';
import { createEnergySlice, EnergySlice } from './energy-slice';
import { createEnergyTimerSlice, EnergyTimerSlice } from './energy-timer-slice';
import { createTaskSlice, TaskSlice } from './task-slice';
import { createUserSlice, UserSlice } from './user-slice';

//TODO slice the store

export type AppStore = UserSlice &
  EnergySlice &
  TaskSlice &
  BoardSlice &
  DialogSlice &
  EnergyTimerSlice;

export const appStore = createStore<AppStore>()(
  persist(
    devtools((...a) => ({
      ...createUserSlice(...a),
      ...createTaskSlice(...a),
      ...createEnergySlice(...a),
      ...createBoardSlice(...a),
      ...createDialogSlice(...a),
      ...createEnergyTimerSlice(...a)
    })),
    {
      name: 'MERGE-X',
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) => !['dialogs', 'ticks', 'energyTimer'].includes(key)
          )
        ),
      storage: createJSONStorage(() => localStorage)
    }
  )
);

export const useAppStore = () => useStore(appStore);
