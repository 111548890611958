import classnames from 'classnames';
import React from 'react';

type ChartBarProps = {
  value: number;
};

export const ChartBar: React.FC<ChartBarProps> = ({ value }) => {
  const val = Math.max(value, 0);
  const percent = Math.min(val / 100, 1) * 100;

  return (
    <div
      className="relative flex justify-center items-center bg-gray-500 min-h-[12px] h-full px-1 w-full
    "
    >
      <div
        className={classnames(
          'absolute h-full top-0 left-0 transition-all duration-300',
          {
            'rounded-r-3xl': percent !== 100,
            'bg-blue-300': percent >= 70,
            'bg-yellow-400': percent < 70 && percent >= 40,
            'bg-rose-500': percent < 40
          }
        )}
        style={{
          width: percent + '%'
        }}
      ></div>
      <span className="z-10 font-bold text-white text-xs">{val} / 100</span>
    </div>
  );
};
