import { Texture, TileArray, TileBox, TileName } from '../../const';
import { BoardPoint } from '../../interfaces';
import { appStore, selectCountEmptyCells } from '../../store';
import { GameElementParams, Tile } from '../tile';

export type GameBoxParams = GameElementParams;

export abstract class GameBox extends Tile {
  constructor({
    scene,
    texture,
    name,
    level,
    point,
    id
  }: {
    scene: Phaser.Scene;
    point: BoardPoint;
    name: TileName;
    texture: Texture;
    level?: number;
    id?: string;
  }) {
    super({
      scene,
      ...point.getCords(),
      texture,
      name,
      type: 'BOX',
      boardPoint: point,
      id,
      level
    });
  }

  containedItems: TileName[] = TileArray.filter((t) => t !== TileBox.BOX);

  open(): void {
    const state = appStore.getState();
    const { level, addRandomTile, removeTileById, closeDialogInfo } = state;

    const disabledCells = this.constants.getDisabledCells(level);
    const emptyCells = selectCountEmptyCells(state, disabledCells);

    if (emptyCells > this.containedItems.length) {
      this.containedItems.forEach((t) =>
        addRandomTile(t, this.level, this.level)
      );
      removeTileById(this.id);
      closeDialogInfo();
    }
  }
}
