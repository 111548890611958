import { TileItem } from '../../const';
import { GameItem, GameItemParams } from './item';

export class Cupcake extends GameItem {
  constructor(params: GameItemParams) {
    super({
      ...params,
      texture: 'cupcake',
      name: TileItem.CUPCAKE
    });
  }
}
