import classNames from 'classnames';
import React, { Children, useEffect } from 'react';
import { Outlet, useMatch } from 'react-router-dom';

import { GameConfig } from '../config';
import { Constants } from '../const';
import { useTelegram } from '../hooks/useTelegram';
import { useAppStore } from '../store';
import { DialogsWrapper } from './dialogs/dialogs-wrapper';
import { Footer } from './footer';
import { Header } from './header';

export const Game = () => {
  const { tg } = useTelegram();

  const { gameWidth, energyIntervalTime } = Constants.getInstance();

  const { startEnergyTimer } = useAppStore();

  const isGameScreen = !!useMatch('/');

  useEffect(() => {
    tg.expand();
    const game = new Phaser.Game(GameConfig);
    document.documentElement.style.setProperty(
      '--game-width',
      game.canvas.getBoundingClientRect().width + 'px'
    );
    document.documentElement.style.setProperty(
      '--app-scale',
      (game.canvas.getBoundingClientRect().width / gameWidth).toString()
    );

    document.documentElement.style.setProperty(
      '--app-height',
      `${window.screen.height}px`
    );

    startEnergyTimer(energyIntervalTime);
  }, []);

  return (
    <>
      <Header isGameScreen={isGameScreen} />
      <div
        id="game-content"
        className={classNames({
          invisible: !isGameScreen
        })}
      />
      <Outlet />
      <Footer isGameScreen={isGameScreen} />
      <DialogsWrapper />
    </>
  );
};
