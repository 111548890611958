import { TileGenerator } from '../../const';
import { GameGenerator, GameGeneratorParams } from './generator';

export class Donut extends GameGenerator {
  constructor(params: GameGeneratorParams) {
    super({
      ...params,
      texture: 'donut',
      name: TileGenerator.DONUT
    });
  }
}
