import React from 'react';

import { selectTasksWithStatus, useAppStore } from '../store';
import { GameDialogTypeEnum, GameTask } from '../types';
import { Task } from './task';

export const TaskList: React.FC = () => {
  const store = useAppStore();

  const { completeTask, addDialog } = store;

  const taskList = selectTasksWithStatus(store);

  const handleCollect = (task: GameTask) => {
    completeTask(task);
  };

  const handleTileClick = (tile: GameTask['items'][number]) => {
    addDialog({
      type: GameDialogTypeEnum.RECIPE,
      content: tile
    });
  };

  return (
    <div className="flex flex-row">
      {taskList.map((t, i) => (
        <Task
          task={t}
          collect={() => handleCollect(t)}
          key={t.id + i}
          tileClick={handleTileClick}
        />
      ))}
    </div>
  );
};
