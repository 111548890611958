import { flatten, memoize } from 'lodash';

import { Constants } from '../const';
import { cellNumber } from '../helpers';
import { BoardItem } from '../types/board-item';
import { BoardSlice } from './board-slice';

const constants = Constants.getInstance();

export const selectFlattenBoardItems = memoize(
  (state: BoardSlice) => flatten(state.board).filter(Boolean) as BoardItem[]
);

export const selectTileOnBoard =
  (state: BoardSlice) => (x: number, y: number) =>
    state.board[y][x];

export const selectCountEmptyCells = memoize(
  (state: BoardSlice, disabledCells?: Set<number>) => {
    let count = 0;

    const rowLength = state.board.length;
    const colLength = state.board[0].length;

    for (let row = 0; row < rowLength; row++) {
      for (let col = 0; col < colLength; col++) {
        if (
          state.board[row][col] === null &&
          !disabledCells?.has(cellNumber(col, row, colLength))
        ) {
          count++;
        }
      }
    }
    return count;
  }
);

export const selectCountElements = (
  state: BoardSlice,
  disabledCells?: Set<number>
) => {
  return constants.boardCapacity - selectCountEmptyCells(state, disabledCells);
};
