import React, { useEffect } from 'react';

function useOutside<T extends HTMLElement = HTMLElement>(
  ref: React.RefObject<T>,
  handler?: (e: TouchEvent | MouseEvent | WheelEvent) => void
) {
  useEffect(() => {
    const listener = (event: TouchEvent | MouseEvent | WheelEvent) => {
      if (
        !ref.current ||
        ref.current.contains((event?.target as Node) || null)
      ) {
        return;
      }
      if ((event.target as HTMLElement)?.id === 'backdrop') {
        event.stopPropagation();
        event.preventDefault();
      }

      handler?.(event);
    };
    document.addEventListener('touchstart', listener);
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('touchstart', listener);
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);
}

export default useOutside;
