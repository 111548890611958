import { clamp } from 'lodash';

import {
  Texture,
  TileGeneratorType,
  TileItemType,
  TileModifier
} from '../../const';
import { isMissed, randomInt } from '../../helpers';
import { BoardItemModifications } from '../../types';
import { GameElementParams, Tile } from '../tile';

export type GameGeneratorParams = GameElementParams & {
  modifications?: BoardItemModifications;
};

const ENERGY_COAST_MULTIPLIER = 1.2;

export abstract class GameGenerator extends Tile {
  private _energyCoast = 1;

  private _freeUseCounter = 0;
  private _maxLevelUseCounter = 0;

  public readonly producedItems: TileItemType[];

  get energyCoast(): number {
    if (this._freeUseCounter > 0) {
      return 0;
    }
    return this._energyCoast;
  }

  get freeUseCounter(): number {
    return this._freeUseCounter;
  }

  get maxLevelUseCounter(): number {
    return this._maxLevelUseCounter;
  }

  constructor({
    scene,
    texture,
    name,
    level,
    id,
    point,
    modifications
  }: GameGeneratorParams & {
    name: TileGeneratorType;
    texture: Texture;
  }) {
    super({
      scene,
      ...point.getCords(),
      texture,
      name,
      type: 'GENERATOR',
      boardPoint: point,
      id,
      level
    });

    this._energyCoast =
      isMissed(level) || level === 1
        ? 1
        : Math.round(level * ENERGY_COAST_MULTIPLIER);

    Object.entries(modifications || {}).forEach(([type, value]) => {
      if (type === TileModifier.BELL) {
        this._maxLevelUseCounter = value;
      } else if (type === TileModifier.BATTERY) {
        this._freeUseCounter = value;
      }
    });

    this.producedItems = this.constants.getProducedItems(name);
  }

  setFreeUse(times: number) {
    this._freeUseCounter = Math.min(times, 0);
  }

  generateTile(): [type: TileItemType, level: number] {
    const tileType =
      this.producedItems[randomInt(0, this.producedItems.length - 1)];
    const tileMaxLevel = this.constants.getMaxLevel(tileType);

    if (this._freeUseCounter > 0) {
      this._freeUseCounter -= 1;
    }

    if (this.maxLevelUseCounter > 0) {
      this._maxLevelUseCounter -= 1;
      return [tileType, clamp(this.level + 1, 1, tileMaxLevel)];
    }

    const level = clamp(this.level, 1, tileMaxLevel);

    const sumOfElements = level * (level + 1);

    const levels: number[] = new Array(level).fill(0).map((_, i) => i + 1);

    const chances: number[] = levels.map((_, i) => (i + 1) / sumOfElements);

    const randNum: number = Math.random();

    let cumulativeProb: number = 0;

    for (let i = 0; i < levels.length; i++) {
      cumulativeProb += chances[i];
      if (randNum < cumulativeProb) {
        return [tileType, levels[i]];
      }
    }

    return [tileType, levels[levels.length - 1]];
  }

  override destroy(fromScene?: boolean | undefined): void {
    super.destroy(fromScene);
  }
}
