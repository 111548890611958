import classNames from 'classnames';
import React, { PropsWithChildren, useEffect, useRef } from 'react';

import { Constants } from '../../const';
import { isTileItemType } from '../../helpers';
import useOutside from '../../hooks/useOutside';
import { BoardItem, ClosableDialog } from '../../types';
import { TileImage } from '../tile-image';
import { Dialog } from './dialog';

const TileIconWrapper: React.FC<
  PropsWithChildren & Partial<{ active: boolean }>
> = ({ children, active }) => {
  return (
    <div
      className={classNames(
        'flex aspect-square px-1 items-center justify-center w-max rounded-2xl shadow-black/40 object-cover',
        {
          'bg-yellow-100  shadow-inner': !active,
          'bg-yellow-400 shadow-sm': active
        }
      )}
    >
      {children}
    </div>
  );
};

type RecipeProps = ClosableDialog & {
  tile: BoardItem;
};

export const RecipeDialog: React.FC<RecipeProps> = ({ tile, close }) => {
  const constants = Constants.getInstance();

  const maxLevel = constants.getMaxLevel(tile.item);
  const generators = isTileItemType(tile.item)
    ? constants.getGeneratorsByTileType(tile.item)
    : [];

  const tileList = [...new Array(maxLevel)].map((_, i) => (
    <div
      className={classNames(
        'relative flex flex-col items-center basis-1/3 justify-center my-2',
        {
          "after:content-['▶'] after:text-yellow-800/50 after:absolute after:right-0 after:top-6 after:translate-x-1/2":
            i !== maxLevel - 1 && (i + 1) % 3 !== 0
        }
      )}
      key={'i' + i}
    >
      <TileIconWrapper active={tile.level === i + 1}>
        <TileImage tile={tile.item} level={i + 1} key={'tile-image' + i} />
      </TileIconWrapper>

      <span className="mt-2 font-bold text-yellow-800/50">{i + 1}</span>
    </div>
  ));

  const generatorsList = generators.map((t, i) => (
    <TileIconWrapper key={t + i}>
      <TileImage tile={t} level={1} className="w-12" />
    </TileIconWrapper>
  ));

  const ref = useRef<HTMLDivElement>(null);

  useOutside(ref, () => close?.());

  return (
    <Dialog
      className="top-1/2 -translate-y-1/2 origin-center p-2"
      showBackdrop={true}
    >
      <div
        className="relative pt-10 pb-2 px-2 -my-2 bg-orange-100 text-black rounded-3xl border-4 border-rose-300 recipe-dialog-animation"
        ref={ref}
      >
        <div className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-rose-500 py-2 px-4 rounded-xl text-white text-center text-3xl">
          <span>{tile.item}</span>
        </div>
        <div className="flex flex-row flex-wrap border-4 bg-orange-50 border-orange-200 rounded-3xl">
          {tileList}
        </div>

        <div className="flex flex-col items-center mt-4">
          <span className="font-bold text-yellow-800 text-center">
            Создается из
          </span>

          <div className="flex flex-row flex-1 w-full items-center justify-center gap-4">
            {generatorsList}
          </div>
        </div>
      </div>
    </Dialog>
  );
};
