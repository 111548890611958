import { Constants, TileModifier, TileName } from '../const';
import {
  cellNumber,
  cellNumberToPoint,
  isMissed,
  isPresent,
  pointInBox
} from '../helpers';
import {
  isTileBox,
  isTileGenerator,
  isTileModifier
} from '../helpers/tile-type-guard.helper';
import { BoardPoint, Point } from '../interfaces';
import { Tile } from '../objects/tile';
import { TileFactory } from '../objects/tile-factory';
import { appStore, selectCountEmptyCells, selectTileOnBoard } from '../store';
import { BoardItem, BoardItemModification } from '../types/board-item';
import { GameDialogTypeEnum } from '../types/dialog';

export class GameScene extends Phaser.Scene {
  private readonly constants = Constants.getInstance();
  private needUpdate = false;

  private tiles!: Phaser.GameObjects.Group;

  private readonly store = appStore;
  // Selected Tiles

  private graphics!: Phaser.GameObjects.Graphics;
  private disabledBlockText!: Phaser.GameObjects.Group;

  private selectedTile: Tile | null = null;
  private activeTile: Tile | null = null;

  private unsubscribe?: () => void;

  constructor() {
    super({
      key: 'GameScene'
    });
  }

  init(): void {
    // Init variables

    this.tiles = this.add.group();

    // set background color
    this.cameras.main.setBackgroundColor(0x6b7280);

    this.graphics = this.add.graphics();
    this.renderBoard();

    const { board, level } = this.store.getState();
    this.renderBoardTiles(board);

    this.disabledBlockText = this.add.group();
    this.renderDisabledCells(level);

    this.unsubscribe = this.store.subscribe(
      ({ board, level }, { board: prevBoard, level: prevLevel }) => {
        if (board !== prevBoard) {
          this.renderBoardTiles(board);
        }

        if (level !== prevLevel) {
          this.renderDisabledCells(level);
        }
      }
    );

    // Selected Tiles
    this.selectedTile = null;

    // Input
    this.input.on('gameobjectdown', this.tileDown, this);
    this.input.on('pointermove', this.tileMove, this);
    this.input.on('pointerup', this.tileUp, this);
  }

  destroy(): void {
    this.unsubscribe?.();

    this.input.off('gameobjectdown', this.tileDown, this);
    this.input.off('pointermove', this.tileMove, this);
    this.input.off('pointerup', this.tileUp, this);
  }

  override update(time: number, delta: number): void {
    if (this.selectedTile) {
      this.renderBoard(
        BoardPoint.fromCords(
          this.selectedTile.x + this.selectedTile.width / 2,
          this.selectedTile.y + this.selectedTile.height / 2
        )
      );
      this.needUpdate = true;
    } else if (this.needUpdate) {
      this.renderBoard();
      this.needUpdate = false;
    }
  }

  private renderDisabledCells(level: number = 1) {
    this.disabledBlockText.clear(true, true);
    const disabledCellsMap = this.constants.getDisabledCellsMap(level);

    if (isMissed(disabledCellsMap)) {
      return;
    }

    const addDisabledText = (
      x: number,
      y: number,
      height: number,
      width: number,
      level: number
    ) => {
      const textBlock = this.add.text(x, y, level.toString(), {
        color: '#fff',
        fontSize: '32px',
        align: 'center',
        fixedWidth: width,
        fixedHeight: height,
        backgroundColor: this.constants.getColorGroup(level)
      });

      const verticalPadding = (textBlock.height - 32) / 2;

      textBlock.setPadding(0, verticalPadding, 0, verticalPadding);

      textBlock.alpha = 0.95;

      this.disabledBlockText.add(textBlock);
    };

    for (const key in disabledCellsMap) {
      const cells = [...disabledCellsMap[key]];

      const [min, max] = [cells[0], cells[cells.length - 1]];

      const leftTopPoint = cellNumberToPoint(min, this.constants.gridWidth);

      const rightBottomPoint = cellNumberToPoint(max, this.constants.gridWidth);

      const leftTopPointCoords = leftTopPoint.getCords();

      const height =
        (rightBottomPoint.y - leftTopPoint.y + 1) * this.constants.tileHeight;
      const width =
        (rightBottomPoint.x - leftTopPoint.x + 1) * this.constants.tileWidth;

      addDisabledText(
        leftTopPointCoords.x,
        leftTopPointCoords.y,
        height,
        width,
        +key
      );
    }
  }

  private renderBoardTiles(b: (BoardItem | null)[][]) {
    this.tiles.clear(true, true);

    // TODO move tiles on board instead clean and render new

    // const tiles = this.tiles.getChildren() as Tile[];

    // console.log('board');

    // const filteredTiles: Tile[] = [];

    // //filter and clean tiles on the board
    // for (let i = 0; i < tiles.length; i++) {
    //   const t = tiles[i];
    //   console.log(t.id);

    //   const pos = findIndex2dArray(b, (item) => item?.id === t.id);

    //   if (pos) {
    //     filteredTiles.push(t);
    //   } else {
    //     this.tiles.remove(t, true, true);
    //     // t.destroy(true);
    //   }
    // }

    for (let y = 0; y < this.constants.gridHeight; y++) {
      for (let x = 0; x < this.constants.gridWidth; x++) {
        const tile = b[y][x];
        if (isPresent(tile)) {
          // const foundedTile = filteredTiles.find((t) => t.id === tile.id);
          // if (foundedTile) {
          //   foundedTile.updatePosition(new BoardPoint(x, y));
          // } else {
          const t = this.addTile({
            id: tile.id,
            x,
            y,
            type: tile.item,
            level: tile.level,
            modifications: tile.modifications
          });

          if (tile.isMergedItem || tile.id === this.activeTile?.id) {
            t.showBorder();
          }
        }
      }
    }

    // if (this.activeTile) {
    //   console.log(this.activeTile.x, this.activeTile.y);
    //   const tile = this.findTileByCoords(this.activeTile.x, this.activeTile.y);

    //   console.log(tile);

    //   tile?.showBorder();
    // }
  }

  private renderBoard(p?: Point) {
    this.graphics.clear();

    for (let y = 0; y < this.constants.gridHeight; y++) {
      for (let x = 0; x < this.constants.gridWidth; x++) {
        const cellColor =
          isPresent(p) && new Point(x, y).isEqual(p) ? 0xfff333 : 0x333333;
        this.graphics
          .fillStyle(cellColor, 1)
          .fillRect(
            x * this.constants.tileWidth,
            y * this.constants.tileHeight + this.constants.headerBottomOffset,
            this.constants.tileWidth,
            this.constants.tileHeight
          );

        this.graphics
          .lineStyle(2, 0xffffff, 1)
          .strokeRect(
            x * this.constants.tileWidth,
            y * this.constants.tileHeight + this.constants.headerBottomOffset,
            this.constants.tileWidth,
            this.constants.tileHeight
          );
      }
    }
  }

  /**
   * Add a new random tile at the specified position.
   * @param x
   * @param y
   */
  private addTile({
    x,
    y,
    type,
    ...props
  }: {
    x: number;
    y: number;
    type?: TileName;
  } & Partial<Pick<BoardItem, 'modifications' | 'level' | 'id'>>): Tile {
    const p = new BoardPoint(x, y);

    const tileProps = {
      scene: this,
      point: p,
      ...props
    };

    const tile = TileFactory.getGameTile(type || 'RANDOM', tileProps);

    this.tiles.add(tile);
    return tile;
  }

  /**
   * This function gets called, as soon as a tile has been pressed or clicked.
   * It will check, if a move can be done at first.
   * Then it will check if a tile was already selected before or not (if -> else)
   * @param pointer
   * @param gameobject
   */
  private tileDown(p: Phaser.Input.Pointer, gameobject: Tile): void {
    if (p.downElement.nodeName !== 'CANVAS') {
      return;
    }

    const cellNum = cellNumber(gameobject.boardPoint, this.constants.gridWidth);
    if (
      this.constants.getDisabledCells(this.store.getState().level).has(cellNum)
    ) {
      return;
    }

    p.event.stopPropagation();
    p.event.preventDefault();

    this.selectedTile = gameobject;
    this.children.bringToTop(this.selectedTile);

    this.hideBoarder();

    const tile = selectTileOnBoard(this.store.getState())(
      gameobject.boardPoint.x,
      gameobject.boardPoint.y
    );

    if (tile) {
      this.store.getState().addDialog({
        content: tile,
        type: GameDialogTypeEnum.TILE_INFO
      });
    }
  }

  private tileMove(pointer: Phaser.Input.Pointer): void {
    this.activeTile = null;
    if (this.selectedTile) {
      this.selectedTile.x = pointer.x - this.selectedTile.width / 2;
      this.selectedTile.y = pointer.y - this.selectedTile.height / 2;
    }
  }

  private tileUp(pointer: Phaser.Input.Pointer): void {
    const tile = this.findTileByCoords(pointer.position.x, pointer.position.y);

    if (!this.selectedTile) {
      this.hideBoarder();
      return;
    }

    if (this.activeTile && tile?.scene && tile?.id === this.activeTile.id) {
      this.tileDblClick(tile);
    }
    if (isMissed(tile)) {
      this.hideBoarder();
    }

    this.activeTile = tile;
    this.activeTile?.showBorder();

    const p = BoardPoint.fromCords(
      this.selectedTile.x + this.selectedTile.width / 2,
      this.selectedTile.y + this.selectedTile.height / 2
    );

    if (!this.checkMerge(this.selectedTile, p)) {
      this.selectedTile.updatePosition(this.selectedTile.boardPoint);
      this.renderBoard();
    }
    this.selectedTile = null;
  }

  private tileDblClick(tile: Tile): void {
    const state = this.store.getState();
    const {
      energy,
      level,
      addRandomTile,
      updateGeneratorTile,
      removeTileById
    } = state;
    const disabledCells = this.constants.getDisabledCells(level);

    const emptyCells = selectCountEmptyCells(state, disabledCells);

    if (isTileGenerator(tile) && energy >= tile.energyCoast && emptyCells > 0) {
      const energyCoast = -tile.energyCoast;
      const modification: BoardItemModification[] = [];

      if (tile.freeUseCounter > 0) {
        modification.push({ type: TileModifier.BATTERY, value: -1 });
      }
      if (tile.maxLevelUseCounter > 0) {
        modification.push({ type: TileModifier.BELL, value: -1 });
      }

      const [type, level] = tile.generateTile();
      addRandomTile(type, level, level);

      updateGeneratorTile({ id: tile.id, modification, energy: energyCoast });
    } else if (isTileBox(tile)) {
      tile.open();
    }
  }

  /**
   *
   * @param type
   * @param point
   * @returns true when tiles merged. False in otherwise
   */
  private checkMerge(tile: Tile, point: BoardPoint) {
    const storeState = this.store.getState();
    const cellNum = cellNumber(point, this.constants.gridWidth);

    if (this.constants.getDisabledCells(storeState.level).has(cellNum)) {
      return false;
    }

    const selectTile = selectTileOnBoard(storeState);

    const t: BoardItem | null = selectTile(point.x, point.y);

    if (isMissed(t)) {
      this.moveTile(tile, point);
      return true;
    }

    const isDifferentTileName = t.item !== tile.tileName;

    if (isDifferentTileName && isTileModifier(tile)) {
      if (tile.canApply(t)) {
        tile.modify(t);
        return true;
      }
      return false;
    }

    if (
      t.id === tile.id ||
      (!isDifferentTileName && t.level === tile.level && tile.isMaxLevel)
    ) {
      return false;
    }

    const { swapTiles, mergeTiles } = storeState;

    if (isDifferentTileName || t.level !== tile.level) {
      swapTiles(point, tile.boardPoint);

      return true;
    }

    const [a, b] = [
      { ...t, point },
      {
        ...(selectTile(tile.boardPoint.x, tile.boardPoint.y) as BoardItem),
        point: tile.boardPoint
      }
    ];

    mergeTiles(a, b);

    return true;
  }

  private moveTile(tile: Tile, point: BoardPoint): void {
    if (
      point.x >= this.constants.gridWidth ||
      point.x < 0 ||
      point.y >= this.constants.gridHeight ||
      point.y < 0
    ) {
      tile.updatePosition(tile.boardPoint);
    } else {
      this.store.getState().swapTiles(point, tile.boardPoint);
    }
  }

  private findTileByCoords(x: number, y: number) {
    const tiles = this.tiles.getChildren() as Tile[];
    return (
      tiles.find((tile) => {
        return pointInBox(tile, { x, y });
      }) || null
    );
  }

  private hideBoarder() {
    (this.tiles.getChildren() as Tile[]).forEach((t) => t.hideBorder());
  }
}
